<template>
  <div class="recruitment">
    <div class="title">
      招聘职位
      <div class="line"></div>
    </div>

    <div class="content">
      <div class="search">
        <div class="condition">
          <span class="filterCondition">筛选条件：</span>

          <span
            @click="clearFilter('level')"
            v-if="queryParams.level"
            class="cursor"
            ><i class="el-icon-close"></i
          ></span>
          <el-dropdown
            style="margin-right: 50px"
            trigger="click"
            placement="bottom-start"
            @command="levelRequireClick"
          >
            <span class="el-dropdown-link">
              <span v-if="queryParams.level">{{
                queryParams.level | levelRequireFormat(LOCALE)
              }}</span>
              <span v-else>学历要求</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in levelRequire"
                :key="item.value"
                :command="item"
                >{{ item.label_zh }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <span
            @click="clearFilter('money')"
            v-if="queryParams.money"
            class="cursor"
            ><i class="el-icon-close"></i
          ></span>
          <el-dropdown
            trigger="click"
            placement="bottom-start"
            @command="moneyClick"
          >
            <span class="el-dropdown-link">
              <span v-if="queryParams.money">{{
                queryParams.money | textFormat
              }}</span>
              <span v-else>薪资待遇</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in salaryList"
                :key="item.value"
                :command="item"
                >{{ item.value }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="clear" @click="clearQueryParams">清空筛选条件</div>
      </div>
      <div class="area" v-loading="loading">
        <positionList :dataList="recruimentList" :showType="2" />
        <div class="footer-content" v-if="total > 10">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :current-page.sync="currentPage"
            :page-size="10"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { levelRequire } from "~scr/utils/enumValue";
// import positionList from "~scr/components/position/positionList";
import positionList from '~hbs/components/position/positionList'

export default {
  name: "recruitment",
  components: {
    positionList,
  },
  props: {
    company_id: {
      type: Number | String,
      default: 0,
    },
    meeting_id: {
      default: 0,
    },
  },
  data() {
    return {
      levelRequire,
      queryParams: {
        level: "",
        money: "",
      },
      dataList: [],
      loading: false,
      salaryList: [
       {
          max: 0,
          min: 0,
          value: "面议",
        },
        {
          max: 3,
          min: 1,
          value: "1-3K",
        },
        {
          max: 5,
          min: 3,
          value: "3-5K",
        },
        {
          max: 10,
          min: 5,
          value: "5-10K",
        },
        {
          max: 15,
          min: 10,
          value: "10-15K",
        },
        {
          max: 20,
          min: 15,
          value: "15-20K",
        },
        {
          max: 100,
          min: 20,
          value: "20K以上",
        },
      ],
      currentPage: 1,
      total: 0,
      recruimentList: [],
    };
  },
  watch: {
    company_id() {},
    currentPage() {
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    levelRequireClick(item) {
      this.queryParams.level = item.value;
      this.getList();
    },
    moneyClick(item) {
      this.queryParams.money = item.value;
      this.getList();
    },
    clearFilter(key) {
      this.queryParams[key] = "";
      this.getList();
    },
    clearQueryParams() {
      this.queryParams.level = "";
      this.queryParams.money = "";
      this.getList();
    },
    async getList() {
      this.loading = true;
      let params = {};
      params.company_id = this.company_id;
      params.meeting_id = this.meeting_id;
      params.level_require = this.queryParams.level;
      let salary = this.salaryList.find((item) => {
        return item.value == this.queryParams.money;
      });
      if (salary) {
        params.salary_lowest = salary.min;
        params.salary_highest = salary.max;
      }
      params.nopage = 0;
      params.start = this.currentPage - 1;
      params.limit = 10;
      try {
        let res = await this.$store.dispatch(
          "API_booth/getPositionList",
          params
        );
        if (res.success) {
          this.recruimentList = res.data;
          this.loading = false;
          this.total = res.count;
          this.$emit("recruitingPositions", this.total);
        }
      } catch (e) {}
    },
  },
};
</script>

<style scoped lang="less">
.recruitment {
  margin: 20px 0;
  .title {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    background-color: #fff;
    padding: 24px 24px 0 24px;
  }
  .line {
    height: 1px;
    width: 100%;
    background: #dcdfe6;
    margin-top: 20px;
  }
  .content {
    .search {
      width: 100%;
      background: #ffffff;
      padding: 20px 63px 20px 30px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 400;

      .condition {
        color: #666666;
      }

      .clear {
        cursor: pointer;
        color: #999999;
      }

      /deep/ .el-dropdown {
        color: #666666;
      }

      .filterCondition {
        margin-right: 30px;
      }

      .requirements {
        margin-right: 70px;
      }
    }
  }
}

.cursor {
  cursor: pointer;
}

/deep/ .el-icon--right {
  margin-left: 0;
}

/deep/ .el-dropdown-link {
  cursor: pointer;
}
.footer-content {
  text-align: right;
}
.area {
  margin-top: 20px;
}
</style>
