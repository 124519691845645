<template>
  <div class="companyVideo" :class="{ companyVideo1: videoList.length == 0 }">
    <div class="title clearfix">
      <div class="fl name">企业多媒体展示</div>
      <div
        class="fr exchange mainColor cursor"
        @click="exchange"
        v-show="videoAllList.length > 3"
      >
        <i class="el-icon-refresh"></i>换一批
      </div>
    </div>
    <div class="videoData" v-loading="loading">
      <div class="video" v-for="(item, index) in videoList" :key="index">
        <div
          v-show="item.aliyun_id"
          class="imgDiv"
          :class="{ cursor: item.aliyun_id }"
          @click="playVideo(item.aliyun_id)"
        >
          <i class="el-icon-video-play iconPlay"></i>
        </div>
        <el-image
          v-show="item.cover_url && item.aliyun_id"
          :src="item.cover_url"
          class="img tc"
          :fit="'cover'"
        ></el-image>
        <el-image
          v-show="item.url && !item.aliyun_id"
          :src="item.url ? item.url : defaultLogo"
          class="img tc"
          :fit="'contain'"
          :preview-src-list="srcList"
        ></el-image>
      </div>
      <noDataImg v-if="!loading && videoList.length == 0"></noDataImg>
    </div>
    <videoPlay ref="vidoePlay"></videoPlay>
  </div>
</template>
<script>
import videoPlay from "@/baseComponents/videoPlay";
export default {
  props: {
    company_id: {
      type: String | Number,
      default: "",
    },
  },
  components: {
    videoPlay,
  },
  data() {
    return {
      defaultLogo: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/company/companyLogo.png",
      videoList: [],
      videoAllList: [],
      currentPage: 0,
      total: 0,
      loading: false,
      srcList: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      let params = {
        company_id: this.company_id,
      };
      let res = await this.$store.dispatch("API_company/getVideoList", params);
      if (res.success) {
        this.loading = false;
        this.videoAllList = res.data;
        let arr = [];
        res.data.forEach((it) => {
          if (it.url) arr.push(it.url);
        });
        this.srcList = arr;
        if (this.videoAllList.length > 3) {
          this.videoList = res.data.slice(0, 3);
        } else {
          this.videoList = res.data;
        }
      } else {
        this.loading = false;
      }
    },
    // 换一换
    exchange() {
      if (this.videoList.length / 3 <= this.currentPage) {
        this.videoList = this.videoAllList.slice(0, 3);
        this.currentPage = 0;
      } else {
        this.currentPage += 3;
        this.videoList = this.videoAllList.slice(
          this.currentPage,
          this.currentPage + 3
        );
      }
    },
    playVideo(id) {
      if (this.IS_LOGIN) {
        this.$refs.vidoePlay.startPlay(id);
      } else {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            this.$refs.vidoePlay.startPlay(id);
          },
        });
      }
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.companyVideo {
  width: 100%;
  height: 642px;
  background: #ffffff;
  padding: 16px;
  .title {
    margin-bottom: 16px;
    .name {
      font-weight: 600;
    }
  }
  .videoData {
    position: relative;
    height: 100%;
    .imgDiv {
      position: absolute;
      top: 0;
      width: 100%;
      height: 180px;
      z-index: 100;
      background: rgba(0, 0, 0, 0.3);
      &:hover {
        background: rgba(0, 0, 0, 0.5);
        i {
          color: #ececec;
        }
      }
      .iconPlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 101;
        font-size: 35px;
        color: #c9c8c8;
      }
    }
    .video {
      width: 100%;
      height: 180px;
      border: 1px solid #ececec;
      margin: 12px 0;
      position: relative;
      .img {
        height: 180px;
      }
    }
  }
}
.companyVideo1 {
  height: 350px !important;
}
</style>
