<template>
  <div class="container">
    <div class="title">业务信息</div>
    <div class="line"></div>
    <div class="list" v-loading = "loading">
      <div v-for="item in labels" :key="item.id" class="item">
        <span class="titleFont">{{ item.name }}</span>
        <span v-if="data[item.key] && data[item.key].length === 0">
          <span class="labelNone">-</span>
        </span>
         <span v-for="(it, ind) in data[item.key]" :key="ind">
           <span class="label" v-if="item.id === 3 "> {{ it.name_en | priorFormat(it.name_zh, LOCALE) }}</span>
           <span class="label" v-if=" item.id === 5"> {{ it.name_en | priorFormat(it.name_cn, LOCALE) }}</span>
           <span class="label" v-if="item.id === 4"> {{ it.code }}</span>
           <span class="label" v-if="item.id === 2 || item.id === 1"> {{ it.desc_en | priorFormat(it.desc_zh, LOCALE) }}</span>
         </span>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "companyBusiness",
    props: {
      company_id: {
        type: String | Number,
        default: ''
      }
    },
    data() {
      return {
        labels: [
          {
            id: 2,
            name: '优势业务',
            key: 'business_classification_list'
          },
          {
            id: 5,
            name: '航线',
            key: 'company_shipping_line'
          },
          {
            id: 4,
            name: '船公司',
            key: 'company_shipping_company'
          },
          {
            id: 3,
            name: '目标业务区域',
            key: 'company_interest_area'
          },
          {
            id: 1,
            name: '目标业务',
            key: 'wanted_business_list'
          },
        ],
        loading: false,
        data: {}
      }
    },
    created() {
      this.getData()
    },
    methods: {
      async getData() {
        let params = {
          company_id: this.company_id,
        }
        this.loading = true
        try {
          let res = await this.$store.dispatch("API_company/getCompanyBusiness",params)
          this.loading = false
          if (res.success) {
            res.data.wanted_business_list = this.handleData(res.data.wanted_business)
            res.data.business_classification_list = this.handleData(res.data.business_classification)
            this.data = Object.assign({}, res.data)
          }
        } catch (e) {
        }
      },
      // 转数据
      handleData(obj) {
        let arr = []
        for(let i in obj) {
          arr = arr.concat(obj[i])
        }
        return arr
      }
    }
  }
</script>

<style scoped lang="less">
  .container {
    margin-top: 20px;
    padding: 24px 24px 25px;
    background: #FFFFFF;
    .title {
      color: #333333;
      font-size: 16px;
      font-weight: 800;
    }
    .line {
      height: 1px;
      background: #DCDFE6;
      width: 100%;
      margin-top: 20px;
    }
    .list {
      padding-top: 16px;
      .item {
        margin-bottom: 15px;
        .titleFont {
          font-weight: 800;
          color: #333333;
          margin-right: 4px;
        }
        .label {
          margin-left: 12px;
          padding: 2px 16px;
          background: #F3F3F3;
          border-radius: 2px;
          border: 1px solid #D9D9D9;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.65);
          display: inline-block;
          margin-bottom: 8px
        }
        .labelNone {
          margin-left: 12px;
          padding: 2px 16px;
          font-size: 12px;
          display: inline-block;
          margin-bottom: 8px
        }
      }
    }
  }
</style>